<footer class="site-footer footer" id="colophon" itemtype="https://schema.org/WPFooter" itemscope="itemscope"
        itemid="#colophon">
        <div class="site-below-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack"
            data-section="section-below-footer-builder">
            <div class="ast-builder-grid-row-container-inner">
                <div class="ast-builder-footer-grid-columns site-below-footer-inner-wrap ast-builder-grid-row">
                    <div class="site-footer-below-section-1 site-footer-section site-footer-section-1">
                        <div class="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright"
                            data-section="section-footer-builder">
                            <div class="ast-footer-copyright">
                                <p>
                                    <a [href]="disclamer_path" target="_blank">{{languageService.transalateText('Disclaimer')}}</a> | <a
                                        [href]="privacy_policy_path" target="_blank">{{languageService.transalateText('Privacy Policy')}}</a> |
                                    <a [href]="tnc_path" target="_blank">{{languageService.transalateText('Terms & Conditions')}}</a> |
                                    Copyright © 2024 Disha Kiran
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </footer>