<div class="tab-section">
  <span class="tab-label {{activeTab == 'psycologist'?'active':''}}"><a
      (click)="switchTab('psycologist')">{{languageService.transalateText('Psycologist')}}</a></span>
  <span class="tab-label {{activeTab == 'user'?'active':''}}"><a
      (click)="switchTab('user')">{{languageService.transalateText('Users')}}</a></span>
</div>
<div *ngIf="activeTab == 'psycologist'" class="header">
  <div class="heading1">{{languageService.transalateText("Psychologist Assessment Tools")}}</div>
  <div class="sub-heading">
    {{languageService.transalateText("Evaluate professional skills and mental well-being as a psychologist")}}
  </div>

  <!-- first test1 -->
  <div *ngIf="homePageData.length<=0 && noTest" class="text-center" style="font-size: 40px;margin-bottom: 150px; margin-top: 200px;">{{languageService.transalateText("Tests will be available soon in this language. Stay tuned!")}}</div>
  <div *ngFor="let category of homePageData; let i = index" class="assessment1">
    <div class="heading3">{{category.title}}</div>
    <div class="sub-heading2">
      {{category.desc}}
    </div>

    <div class="grid-container">
      <!-- Card 1st -->
      <div *ngFor="let test of category.tests;let ii=index;" class="card" [style.background-image]="getColor(ii)" (click)="goToQuizPage(test.test_type_id)">
        <div class="content">
          <div class="icon">
            <img src="{{test.icon_img}}" alt="{{test.title}}" width="25px" height="25px" />
          </div>
          <div id="text">

            <div class="icon-heading">
              {{test.title}}
            </div>
            
            <div class="icon-content">
              {{test.desc}}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="break-line1"></div>
  </div>
</div>
<div *ngIf="activeTab == 'user'" class="header">
  <div class="heading1">{{languageService.transalateText("Psychologist Assessment Tools")}}</div>
  <div class="sub-heading">
    {{languageService.transalateText("Evaluate professional skills and mental well-being as a psychologist")}}
  </div>

  <!-- first test1 -->
  <div *ngIf="userHomePageData.length<=0 && noTest" class="text-center" style="font-size: 40px;margin-bottom: 150px; margin-top: 200px;">{{languageService.transalateText("Tests will be available soon in this language. Stay tuned!")}}</div>
  <div *ngFor="let category of userHomePageData; let i = index" class="assessment1">
    <div class="heading3">{{category.title}}</div>
    <div class="sub-heading2">
      {{category.desc}}
    </div>

    <div class="grid-container">
      <!-- Card 1st -->
      <div *ngFor="let test of category.tests;let ii=index;" class="card" [style.background-image]="getColor(ii)" (click)="goToQuizPage(test.test_type_id)">
        <div class="content">
          <div class="icon">
            <img src="{{test.icon_img}}" alt="{{test.title}}" width="25px" height="25px" />
          </div>
          <div id="text">

            <div class="icon-heading">
              {{test.title}}
            </div>
            
            <div class="icon-content">
              {{test.desc}}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="break-line1"></div>
  </div>
</div>
  