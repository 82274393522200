<div class="inst-container">
    <main>
        <div class="row">
            <div class="col-9">
                <section class="test-header">
                    <h2>{{testDetails.category_title}}</h2>
                    <h1>{{testDetails.test_title}}</h1>
                    <p class="description">{{testDetails.test_desc}}</p>
                    <div class="test-info">
                        <span>📝 {{testDetails.questionCount}} questions</span>
                        <span *ngIf="testDetails.timer">⏳ {{testDetails.timer/60}} minutes</span>
                    </div>
                </section>
            </div>
            <div class="col-3">
                <img [src]="testDetails.question_img" class="test-img">
            </div>
        </div>
        <div class="break-line1 mt-1 mb-4"></div>
        <section class="test-details" [innerHTML]="testDetails.disclaimer_body">
        </section>
        <div class="mt-2 age-select" *ngIf="showAgeSelection">

            <input type="radio" value="13" [(ngModel)]="selectedAge" (ngModelChange)="refreshTestDetails()" id="13"
                name="age" class="answer">
            <label for="13">
                &nbsp;{{languageService.transalateText('Below 18 Years')}}
                &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <br>
            <input type="radio" value="18" id="13+" [(ngModel)]="selectedAge" (ngModelChange)="refreshTestDetails()"
                name="age" class="answer">
            <label for="13+">
                &nbsp;{{languageService.transalateText('18 Years or Above')}}
            </label>

        </div>
    </main>

    <div class="start-button-container">
        <button class="start-button" (click)="startTest()">{{testDetails.button_text}}</button>
    </div>
</div>