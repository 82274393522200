import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if(authService.isLoggedIn()){
    return true
  } else{
    if(state.url.includes('psycologist')){
      router.navigate(['psycologistRegister'])
    } else {
      router.navigate(['/register']);
    }
    return false;
  }
};
