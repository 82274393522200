import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../services/language-service.service';
import { ApiResponse } from '../../model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {

  formData: any = {};
  quizId:number = 0;
  constructor(private AS: AuthService, private route: ActivatedRoute, private router: Router, public languageService: LanguageService) {

  }

  ngOnInit(): void {
    this.initCheckAlreadyLoggedIn();
    this.getRouteParams();
  }
  initCheckAlreadyLoggedIn() {
    if(this.AS.isLoggedIn()){
      this.router.navigate(['/']);
    }
  }

  getRouteParams() {
    this.route.params.subscribe(params => {
      this.quizId = params['quizId'];
    });
    // let referBy = this.route.snapshot.queryParams['referBy'];
    // if (referBy) {
    //   this.AS.commandinDB('set', 'referal_code', referBy);
    // }
  }

  async login() {
    if (this.quizId) {
      this.router.navigate(['login', this.quizId]);
    } else {
      this.router.navigate(['login']);
    }
  }


  formValidation() {
    if (this.formData.pass == this.formData.cpass) {
      if (this.formData.pass.length > 0 && this.formData.cpass.length > 0) {
        return true;
      }
    }

    return false;
  }

  validateUsername(username: string) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(username).toLowerCase())) {
      return true;
    };

    re = /[6-9]{1}[0-9]{9}$/
    if (re.test(username)) {
      return true
    }
    return false

  }


  async register() {
    let validateForm = await this.formValidation();
    if (validateForm == false) {
      this.AS.alert('password & confirm password mismatch');
      return;
    }
    
    if (!this.validateUsername(this.formData['user'])) {
      this.AS.alert('Enter Valid Email');
      return;
    }

    let credential:any = {
      "username": this.formData['user'],
      "fullname": this.formData['name'],
      "password": this.formData['pass']
    }
    let referal_code = this.AS.commandinDB('get', 'referal_code');
    if(referal_code){
      credential.referBy = referal_code;
    }

    this.AS.register(credential).subscribe({
      next: (res: ApiResponse<any>) => {
        let checkRes = this.AS.checkRes(res);
        if (res['status'] == 'success') {
          if (checkRes == 'data') {
            alert('Registered successfully');
            // this.AS.alert('Registered successfully');
            // window.location.assign('/');
            this.login();
          } else {
            this.AS.alert('Registration failed, please try again');
          }
        } else {
          this.AS.alert(<string>res['message']);
        }
      },
      error: (err) => {
        this.AS.alert(err.error.message);
        console.log('Err:', err);
      }
    });
    
  }
}
