import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../services/language-service.service';
import { ApiResponse } from '../../model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import * as Sentry from "@sentry/angular"


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements AfterViewInit, OnDestroy {
  @ViewChild('pswdBox') pswdBox!: ElementRef;
  showPassword:boolean = false;
  routeParams: any;
  socialUser: any;
  loggedIn: boolean = false;
  subscription: any;
  isGuest: boolean = false;
  formData: any = {};
  checkPayload: any = null;
  quizType = ['riasec', 'mbti', 'iq'];
  user = {
    username: null,
    fullname: null
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordField: any = document.getElementById('password');
    passwordField.type = this.showPassword ? 'text' : 'password';
  }

  constructor(private AS: AuthService, private route: ActivatedRoute, 
    private router: Router, public languageService: LanguageService) {

  }

  ngOnInit() {
    this.initCheckAlreadyLoggedIn();
    this.getRouteParams();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  ngAfterViewInit() {

  }


  initCheckAlreadyLoggedIn() {
    if(this.AS.isLoggedIn()){
      this.router.navigate(['/'])
    }
  }

  getRouteParams() {
    this.route.params.subscribe(params => {
      if (!!params['quizId']) {
        let quizId = params['quizId'];
        let paramData = { 'name': this.quizType[quizId - 1], 'type': quizId };
        this.routeParams = paramData;
      }

    });
    let referBy = this.route.snapshot.queryParams['referBy'];
    if (referBy) {
      this.AS.commandinDB('set', 'referal_code', referBy);
    }
    
  }

  async register() {
    if (this.routeParams != undefined) {
      this.router.navigate(['register', this.routeParams.type]);
    } else {
      this.router.navigate(['register']);
    }

  }

  async forgetPassword() {
    if (this.routeParams != undefined) {
      this.router.navigate(['forgetpassword', this.routeParams.type]);
    } else {
      this.router.navigate(['forgetpassword']);
    }
  }



  async login() {
    let credential = { 'username': this.formData['user'], 'password': this.formData['pass'] };
    this.AS.loggingIn(credential).subscribe({
      next: (res: ApiResponse<any>) => {
        let checkRes = this.AS.checkRes(res);    
        if (res['status'] === 'success') {
          if (checkRes === 'data') {
            // this.AS.commandinDB('set', 'referal_code', res['data']['referal_code']);
  
            if (res['data']['token'] == null || res['data']['token'] === undefined) {
              this.AS.alert('Fatal Err: Login verification failed, Please try again');
              console.log('Fatal Err: Login verification failed, Please try again =>', 'No token Found');
            } else {
              this.user.fullname = res['data']['fullname'];
              this.user.username = this.formData['user'];
              Sentry.setUser({ email:  <string>this.formData['user']});
              this.loginSuccessGo(res);
            }
          } else {
            this.AS.alert('SomeError Occur');
          }
        } else {
          this.AS.alert(<string>res.message);
        }
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      }
    });
  }

  // async socialLogin() {
  //   var username = this.socialUser.email;
  //   if (!username) {
  //     username = this.socialUser.id
  //   }
  //   let credential: any = {
  //     "username": username,
  //     "uniqueToken": this.socialUser.idToken,
  //     "loginWith": this.socialUser.provider,
  //     "user_id": this.socialUser.id,
  //     "fullname": this.socialUser.name
  //   }
  //   let referal_code = this.AS.commandinDB('get', 'referal_code');
  //   if (referal_code) {
  //     credential.referBy = referal_code;
  //   }
  //   this.AS.socialLoggingIn(credential).subscribe(res => {

  //     console.log('Loggin In:', res);
  //     let checkRes = this.AS.checkRes(res);
  //     if (checkRes == 'data') {
  //       if (res['status'] == 'success') {
  //         this.user.username = username
  //         this.user.fullname = res['data']['fullname']
  //         this.loginSuccessGo(res);
  //       } else {
  //         this.AS.alert('Username of password incorrect');
  //       }

  //     } else {
  //       this.AS.alert('empty data');
  //     }

  //   }, (err) => {
  //     this.AS.alert('Internet Error! please check your internet');
  //     this.AS.loggedOut()
  //     this.socialUser = undefined;
  //     console.log('Err:', err);
  //   })



  // }


  async loginSuccessGo(res: ApiResponse<any>) {
    let session: any = {};
    session['sessionId'] = res['data']['token'];
    this.AS.sessionCTRL('set', session);
    this.loggedIn = true;
    if (this.socialUser != undefined) {
      this.socialUser['isGuest'] = this.isGuest;
      this.socialUser['username'] = this.user.username
      this.socialUser['fullname'] = this.user.fullname
      await this.AS.userCTRL('set', JSON.stringify(this.socialUser));
    } else {
      res['data']['isGuest'] = this.isGuest;
      res['data']['username'] = this.user.username
      res['data']['fullname'] = this.user.fullname
      await this.AS.userCTRL('set', JSON.stringify(res['data']));
    }


    if (this.routeParams != undefined) {
      this.router.navigate(['/quiz', this.routeParams.type]);
    }
    else {
      this.AS.alert('Logged in successfully');
      window.location.assign('/');
    }

  }

  showHidePassword() {
    if (this.pswdBox.nativeElement.type == 'password') {
      this.pswdBox.nativeElement.type = 'text';
    } else {
      this.pswdBox.nativeElement.type = 'password';
    }
  }

}
