import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  TEXTS: { [key: string]: any } = {
    "HOME": {
      2: "होम",
      3: "ਘਰ",
      4: "હોમપેજ",
      12: "ଘର",
      6: "ಹೋಮ್",
      10: "গৃহ",
      8: "హోమ్",

    },
    "PROFILE": {
      2: "प्रोफ़ाइल",
      3: "ਪ੍ਰੋਫਾਈਲ",
      4: "પ્રોફાઇલ",
      12: "ପ୍ରୋଫାଇଲ୍",
      6: "ಪ್ರೊಫೈಲ್",
      10: "ৰূপৰেখা", 8: "ప్రొఫైల్",
    },
    "Disclaimer": {
      2: "अस्वीकरण",
      3: "ਬੇਦਾਅਵਾ",
      4: "અસ્વીકરણ",
      12: "ପ୍ରତ୍ୟାଖ୍ୟାନ",
      6: "ಹಕ್ಕು ನಿರಾಕರಣೆ",
      10: "ৰূপৰেখা", 8: "నిరాకరణ",
    },
    "Privacy Policy": {
      2: "गोपनीयता नीति",
      3: "ਪਰਾਈਵੇਟ ਨੀਤੀ",
      4: "ગોપનિયતા નીતિ",
      12: "ଗୋପନୀୟତା ନୀତି",
      6: "ಗೌಪ್ಯತಾ ನೀತಿ",
      10: "গোপনীয়তা নীতি", 8: "గోప్యతా విధానం",
    },
    "Terms & Conditions": {
      2: "नियम एवं शर्तें",
      3: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
      4: "નિયમ અને શરતો",
      12: "ସର୍ତ୍ତାବଳୀ",
      6: "ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು",
      10: "চৰ্তাৱলী", 8: "నిబంధనలు & షరతులు",
    },
    "Question": {
      2: "प्रश्न",
      3: "ਸਵਾਲ",
      4: "પ્રશ્ન",
      12: "ପ୍ରଶ୍ନ",
      6: "ಪ್ರಶ್ನೆ",
      10: "প্ৰশ্ন", 8: "ప్రశ్న",
    },
    "SUBMIT TEST": {
      2: "परीक्षा सबमिट करें",
      3: "ਟੈਸਟ ਜਮ੍ਹਾਂ ਕਰੋ",
      4: "પરીક્ષા સબમિટ કરો",
      12: "ସବମିଟ୍ ପରୀକ୍ଷା",
      6: "ಪರೀಕ್ಷೆಯನ್ನು ಸಲ್ಲಿಸಿ",
      10: "পৰীক্ষা দাখিল কৰক", 8: "పరీక్షను సమర్పించండి",
    },
    "Full Name": {
      2: "पूरा नाम",
      3: "ਪੂਰਾ ਨਾਂਮ",
      4: "પૂરું નામ",
      12: "ପୁରା ନାମ",
      6: "ಪೂರ್ಣ ಹೆಸರು",
      10: "সম্পূৰ্ণ নাম",
      8: "పూర్తి పేరు",
    },
    "Email": {
      2: "ईमेल",
      3: "ਈਮੇਲ",
      4: "ઈમેલ અથવા મોબાઈલ નંબર",
      12: "ଇମେଲ୍",
      6: "ಇಮೇಲ್",
      10: "ইমেইল",
      8: "ఇమెయిల్",
    },
    "Submit": {
      2: "सबमिट करे",
      3: "ਜਮ੍ਹਾਂ ਕਰੋ",
      4: "સબમિટ કરો",
      12: "ଦାଖଲ କରନ୍ତୁ",
      6: "ಸಲ್ಲಿಸು",
      10: "দাখিল কৰক",
      8: "సమర్పించండి",
    },
    "Have a coupon code? Apply here": {
      2: "यदी कूपन कोड है तो नीचे टाइप करें, अन्यथा चुकाने की कार्यवाही करें",
      3: "ਕੀ ਇੱਕ ਕੂਪਨ ਕੋਡ ਹੈ? ਇੱਥੇ ਅਪਲਾਈ ਕਰੋ",
      4: "તમારી પાસે કુપન કોડ છે? અહી દાખલ કરો",
      12: "ଏକ କୁପନ୍ କୋଡ୍ ଅଛି କି? ଏଠାରେ ଆବେଦନ କରନ୍ତୁ",
      6: "ಕೂಪನ್ ಕೋಡ್ ಹೊಂದಿರುವಿರಾ? ಇಲ್ಲಿ ಅನ್ವಯಿಸಿ",
      10: "কুপন ক'ড আছেনে? ইয়াত আবেদন কৰক",
      8: "కూపన్ కోడ్ ఉందా? ఇక్కడ దరఖాస్తు చేసుకోండి",
    },
    "Enter Coupon Code": {
      2: "कूपन कोड दर्ज करें",
      3: "ਕੂਪਨ ਕੋਡ ਦਰਜ ਕਰੋ",
      4: "કૂપન કોડ દાખલ કરો",
      12: "କୁପନ୍ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
      6: "ಕೂಪನ್ ಕೋಡ್ ನಮೂದಿಸಿ",
      10: "কুপন ক'ড প্ৰৱেশ কৰক",
      8: "కూపన్ కోడ్‌ని నమోదు చేయండి",
    },
    "Apply": {
      2: "लागू करें",
      3: "ਲਾਗੂ ਕਰੋ",
      4: "લાગુ કરો",
      12: "ପ୍ରୟୋଗ କରନ୍ତୁ",
      6: "ಅನ್ವಯಿಸು",
      10: "প্ৰয়োগ কৰক",
      8: "దరఖాస్తు చేసుకోండి",
    },
    "Summary": {
      2: "सारांश",
      3: "ਸੰਖੇਪ",
      4: "સારાંશ",
      12: "ସାରାଂଶ",
      6: "ಸಾರಾಂಶ",
      10: "সাৰাংশ",
      8: "సారాంశం",
    },
    "Payable Amount": {
      2: "भुगतान योग्य राशि",
      3: "ਭੁਗਤਾਨਯੋਗ ਰਕਮ",
      4: "ચુકવવાપાત્ર રકમ",
      12: "ଦେୟ ରାଶି",
      6: "ಪಾವತಿಸಬೇಕಾದ ಮೊತ್ತ",
      10: "প্ৰদানযোগ্য ধনৰাশি",
      8: "చెల్లించవలసిన మొత్తం",
    },
    "Coupon Applied": {
      2: "कूपन लागू हो गई है।",
      3: "ਕੂਪਨ ਲਾਗੂ ਕੀਤਾ ਗਿਆ",
      4: "કૂપન લાગૂ થઈ ગઈ છે.",
      12: "କୁପନ୍ ପ୍ରୟୋଗ",
      6: "ಕೂಪನ್ ಅನ್ವಯಿಸಲಾಗಿದೆ",
      10: "কুপন প্ৰয়োগ কৰা হৈছে",
      8: "కూపన్ వర్తింపజేయబడింది",
    },
    "Total Amount": {
      2: "कुल राशि",
      3: "ਕੁੱਲ ਮਾਤਰਾ",
      4: "કુલ રકમ",
      12: "ମୋଟ ରାଶି",
      6: "ಒಟ್ಟು ಮೊತ್ತ",
      10: "মুঠ ধনৰাশি", 8: "మొత్తం మొత్తం",
    },
    "Proceed to pay": {
      2: "चुकाने की कार्यवाही शूरू करें",
      3: "ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਅੱਗੇ ਵਧੋ",
      4: "ચૂકવણી કરવા માટે આગળ વધો ",
      12: "ଦେୟ ଅଗ୍ରଗତି କର",
      6: "ಪಾವತಿಸಲು ಮುಂದುವರಿಯಿರಿ",
      10: "ধন দিবলৈ আগবাঢ়ি যাওক", 8: "చెల్లించడానికి కొనసాగండి",
    },
    "User Profile": {
      2: "उपयोगकर्ता प्रोफ़ाइल",
      3: "ਯੂਜ਼ਰ ਪ੍ਰੋਫ਼ਾਈਲ",
      4: "વપરાશકર્તા પ્રોફાઇલ",
      12: "ଉପଯୋଗକର୍ତ୍ତା ପ୍ରୋଫାଇଲ୍",
      6: "ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್",
      10: "ব্যৱহাৰকাৰী প্ৰ'ফাইল", 8: "వినియోగదారు వివరాలు",
    },
    "Name": {
      2: "नाम",
      3: "ਨਾਮ",
      4: "નામ",
      12: "ନାମ",
      6: "ಹೆಸರು",
      10: "নাম", 8: "పేరు",
    },
    "User Name": {
      2: "उपयोगकर्ता नाम",
      3: "ਉਪਭੋਗਤਾ ਨਾਮ",
      4: "વપરાશકર્તા નામ",
      12: "ଉପଯୋଗକର୍ତ୍ତା ନାମ",
      6: "ಬಳಕೆದಾರ ಹೆಸರು",
      10: "ব্যৱহাৰকাৰীৰ নাম", 8: "వినియోగదారు పేరు",
    },
    "Logout": {
      2: "लॉग आउट",
      3: "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
      4: "લોગઆઉટ",
      12: "ପ୍ରସ୍ଥାନ କର",
      6: "ಲಾಗ್ ಔಟ್",
      10: "লগআউট কৰক", 8: "లాగ్అవుట్",
    },
    "View Result": {
      2: "परिणाम देखे",
      3: "ਨਤੀਜਾ ਵੇਖੋ",
      4: "પરિણામ જુઓ",
      12: "ଫଳାଫଳ ଦେଖନ୍ତୁ",
      6: "ಫಲಿತಾಂಶವನ್ನು ವೀಕ್ಷಿಸಿ",
      10: "ফলাফল চাওক", 8: "ఫలితాన్ని వీక్షించండి",
    },
    "Login": {
      2: "लॉग इन करें",
      3: "ਲਾਗਿਨ",
      4: "લૉગ-ઇન કરો ",
      12: "ଭିତରକୁ ଯାଉ",
      6: "ಲಾಗಿನ್",
      10: "লগইন কৰক", 8: "ప్రవేశించండి",
    },
    "Password": {
      2: "पासवर्ड",
      3: "ਪਾਸਵਰਡ",
      4: "પાસવર્ડ",
      12: "ପାସୱାର୍ଡ",
      6: "ಗುಪ್ತಪದ",
      10: "পাছৱৰ্ড", 8: "పాస్వర్డ్",
    },
    "Don't have an account? ":{
      2:"क्या आपका अकाउंट नहीं है?"
    },
    "Register Here":{
      2: "यहाँ पंजीकरण करें"
    },
    "Forgot password?": {
      2: "पासवर्ड भूल गए?",
      3: "ਪਾਸਵਰਡ ਭੁੱਲ ਜਾਓ?",
      4: "પાસવર્ડ ભૂલી ગયા?",
      12: "ପାସୱାର୍ଡ ଭୁଲିଯାଅ?",
      6: "ಪಾಸ್ವರ್ಡ್ ಮರೆತುಬಿಡಿ?",
      10: "পাছৱৰ্ড পাহৰি যাওক?",
      8: "పాస్వర్డ్ను మర్చిపో?",
    },
    "Continue": {
      2: "आगे बढे"
    },
    "Sign Up": {
      2: "साइन अप करें",
      3: "ਸਾਇਨ ਅਪ",
      4: "સાઇન-અપ કરો",
      12: "ସାଇନ୍ ଅପ୍ କରନ୍ତୁ",
      6: "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
      10: "চাইন আপ কৰক", 8: "చేరడం",
    },
    "Enter full name": {
      2: "पूरा नाम दर्ज करें",
      3: "ਪੂਰਾ ਨਾਮ ਦਰਜ ਕਰੋ",
      4: "પૂરું નામ દાખલ કરો",
      12: "ପୂର୍ଣ୍ଣ ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
      6: "ಪೂರ್ಣ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
      10: "সম্পূৰ্ণ নাম লিখক", 8: "పూర్తి పేరును నమోదు చేయండి",
    },
    "Confirm Password": {
      2: "पासवर्ड की पुष्टि कीजिये",
      3: "ਪੁਸ਼ਟੀ ਕਰੋ ਪਾਸਵਰਡ",
      4: "પાસવર્ડ ની પુષ્ટિ કરો",
      12: "ପାସ୍ବାଡ଼ ନିସ୍ଚିତ କର",
      6: "ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ",
      10: "পাছৱৰ্ড নিশ্চিত কৰক", 8: "పాస్‌వర్డ్‌ని నిర్ధారించండి",
    },
    "Back to Login": {
      2: "लॉगिन पर वापस जाएं",
      3: "ਲੌਗਇਨ 'ਤੇ ਵਾਪਸ ਜਾਓ",
      4: "લૉગ-ઇન કરવા પાછા જાઓ ",
      12: "ଲଗଇନ୍ କୁ ଫେରନ୍ତୁ",
      6: "ಲಾಗಿನ್ ಗೆ ಹಿಂತಿರುಗಿ",
      10: "লগইনলৈ উভতি যাওক", 8: "తిరిగి లాగిన్‌కి",
    },
    "Register": {
      2: "रजिस्टर करें",
      3: "ਰਜਿਸਟਰ",
      4: "રજીસ્ટર કરો",
      12: "ପଞ୍ଜିକରଣ କର",
      6: "ನೋಂದಣಿ",
      10: "পঞ্জীয়ন কৰা", 8: "నమోదు చేసుకోండి",
    },
    "By clicking": {
      2: "क्लिक करने से",
      3: "ਕਲਿਕ ਕਰਕੇ",
      4: "ક્લિક કરવાથી",
      12: "କ୍ଲିକ୍ କରି",
      6: "ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ",
      10: "ক্লিক কৰি", 8: "క్లిక్ చేయడం ద్వారా",
    },
    "you agree to the": {
      2: "आप उनसे सहमत हैं जो",
      3: "ਤੁਸੀਂ ਇਸ ਨਾਲ ਸਹਿਮਤ ਹੋ",
      4: "તમે તે સાથે સહમત છો જે",
      12: "ଆପଣ ଏଥିରେ ସହମତ",
      6: "ನೀವು ಒಪ್ಪುತ್ತೀರಿ",
      10: "আপুনি সন্মত হৈছে", 8: "మీరు అంగీకరిస్తున్నారు",
    },
    "Terms and Conditions": {
      2: "नियम और शर्तें हैं।",
      3: "ਨਿਬੰਧਨ ਅਤੇ ਸ਼ਰਤਾਂ",
      4: "નિયમો અને શરતો",
      12: "ସର୍ତ୍ତାବଳୀ",
      6: "ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು",
      10: "চৰ্ত আৰু নিয়মসমূহ",
      8: "నిబంధనలు మరియు షరతులు",
    },
    "Create Account":{
      2: "अकाउंट बनाएं"
    },
    "set out by this site, including our Cookie Use.": {
      2: "इस साइट द्वारा निर्धारित किया गया है, जिसमें हमारा कुकी उपयोग भी शामिल है।",
      3: "ਸਾਡੀ ਕੂਕੀ ਵਰਤੋਂ ਸਮੇਤ, ਇਸ ਸਾਈਟ ਦੁਆਰਾ ਨਿਰਧਾਰਤ ਕੀਤੀ ਗਈ ਹੈ।",
      4: "આ વેબસાઇટ દ્વારા નિર્ધારિત કરવામાં આવ્યા છે જેમાં અમારો કુકી ઉપયોગ પણ સામેલ છે",
      12: "ଆମର କୁକି ବ୍ୟବହାରକୁ ଅନ୍ତର୍ଭୁକ୍ତ କରି ଏହି ସାଇଟ୍ ଦ୍ୱାରା ସେଟ୍ ହୋଇଛି",
      6: "ನಮ್ಮ ಕುಕಿ ಬಳಕೆ ಸೇರಿದಂತೆ ಈ ಸೈಟ್‌ನಿಂದ ಹೊಂದಿಸಲಾಗಿದೆ",
      10: "এই চাইটৰ দ্বাৰা নিৰ্ধাৰিত, আমাৰ কুকি ব্যৱহাৰকে ধৰি",
      8: "మా కుక్కీ వినియోగంతో సహా ఈ సైట్ ద్వారా సెట్ చేయబడింది.",
    },
    "Get OTP": {
      2: "ओटीपी प्राप्त करें",
      3: "OTP ਪ੍ਰਾਪਤ ਕਰੋ",
      4: "OTP મેળવો",
      12: "OTP ପାଆନ୍ତୁ",
      6: "OTP ಪಡೆಯಿರಿ",
      10: "OTP পাওক", 8: "OTP పొందండి",
    },
    "Submit OTP": {
      2: "ओटीपी सबमिट करें",
      3: "OTP ਸਪੁਰਦ ਕਰੋ",
      4: "OTP સબમિટ  કરો ",
      12: "OTP ଦାଖଲ କରନ୍ତୁ",
      6: "OTP ಸಲ್ಲಿಸಿ",
      10: "OTP জমা দিয়ক", 8: "OTPని సమర్పించండి",
    },
    "OTP is sent to your registered Email.": {
      2: "ओटीपी आपके पंजीकृत ईमेल पर भेजा जाता है।",
      3: "OTP ਤੁਹਾਡੇ ਰਜਿਸਟਰਡ ਈਮੇਲ 'ਤੇ ਭੇਜਿਆ ਜਾਂਦਾ ਹੈ।",
      4: "તમારા રજિસ્ટર્ડ ઈમેલ પર OTP મોકલવામાં આવ્યો છે.",
      12: "OTP ଆପଣଙ୍କର ପଞ୍ଜୀକୃତ ଇମେଲ ପଠାଯାଏ",
      6: "ನಿಮ್ಮ ನೋಂದಾಯಿತ ಇಮೇಲ್ OTP ಕಳುಹಿಸಲಾಗಿದೆ.",
      10: "আপোনাৰ পঞ্জীয়নভুক্ত ইমেইল অ’টিপি প্ৰেৰণ কৰা হয়",
      8: "మీ నమోదిత ఇమెయిల్ OTP పంపబడుతుంది.",
    },
    "Enter OTP": {
      2: "ओटीपी दर्ज करें",
      3: "OTP ਦਾਖਲ ਕਰੋ",
      4: "OTP દાખલ કરો ",
      12: "OTP ପ୍ରବେଶ କରନ୍ତୁ",
      6: "OTP ನಮೂದಿಸಿ",
      10: "OTP প্ৰৱেশ কৰক", 8: "OTPని నమోదు చేయండి",
    },
    "Reset Password": {
      2: "पासवर्ड रीसेट करे",
      3: "ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰੋ",
      4: "પાસવર્ડ રીસેટ કરો",
      12: "ପାସୱାର୍ଡ ପୁନ Res ସେଟ୍ କରନ୍ତୁ",
      6: "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
      10: "পাছৱৰ্ড পুনৰায় সেট কৰক", 8: "రహస్యపదాన్ని మార్చుకోండి",
    },
    "Enter new password": {
      2: "नया पासवर्ड दर्ज करें",
      3: "ਨਵਾਂ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ",
      4: "નવો પાસવર્ડ દાખલ કરો",
      12: "ନୂତନ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ",
      6: "ಹೊಸ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ",
      10: "নতুন পাছৱৰ্ড দিয়ক", 8: "కొత్త పాస్‌వర్డ్‌ను నమోదు చేయండి",
    },
    "Paid Results": {
      2: "विस्तृत परिणाम",
      3: "ਭੁਗਤਾਨ ਕੀਤੇ ਨਤੀਜੇ",
      4: "વિગતવાર પરિણામો",
      12: "ଦେୟ ଫଳାଫଳ",
      6: "ಪಾವತಿಸಿದ ಫಲಿತಾಂಶಗಳು",
      10: "পেইড ৰিজাল্ট", 8: "చెల్లింపు ఫలితాలు",
    },
    " Free Results": {
      2: "संक्षिप्त परिणाम",
      3: "ਮੁਫ਼ਤ ਨਤੀਜੇ",
      4: "ટૂંકા પરિણામો",
      12: "ମାଗଣା ଫଳାଫଳ",
      6: "ಉಚಿತ ಫಲಿತಾಂಶಗಳು",
      10: "বিনামূলীয়া ফলাফল", 8: "ఉచిత ఫలితాలు",
    },
    "Express your consent by answering the following questions from 0 to 10": {
      2: "निमनलिखित प्रश्नों के जवाब में 0 से 10 तक अपनी सहमति व्यक्त करें",
      3: "0 ਤੋਂ 10 ਤੱਕ ਹੇਠਾਂ ਦਿੱਤੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦੇ ਕੇ ਆਪਣੀ ਸਹਿਮਤੀ ਜ਼ਾਹਰ ਕਰੋ",
      4: '0 થી 10 ના નીચેના પ્રશ્નોના જવાબ આપીને તમારી સંમતિ વ્યક્ત કરો',
      12: "0 ରୁ 10 ପର୍ଯ୍ୟନ୍ତ ନିମ୍ନ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେଇ ଆପଣଙ୍କର ସମ୍ମତି ପ୍ରକାଶ କରନ୍ତୁ",
      6: "0 ರಿಂದ 10 ರವರೆಗಿನ ಕೆಳಗಿನ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸುವ ಮೂಲಕ ನಿಮ್ಮ ಒಪ್ಪಿಗೆಯನ್ನು ವ್ಯಕ್ತಪಡಿಸಿ",
      10: "তলত দিয়া প্ৰশ্নসমূহৰ উত্তৰ ০ৰ পৰা ১০লৈ দি আপোনাৰ সন্মতি প্ৰকাশ কৰক",
      8: "కింది ప్రశ్నలకు 0 నుండి 10 వరకు సమాధానం ఇవ్వడం ద్వారా మీ సమ్మతిని తెలియజేయండి",
    },
    "Click on any one of the below options": {
      2: "नीचे दिए गए विकल्प में से किसी एक को क्लिक करें",
      3: "ਪ੍ਰੋਫਾਈਲ",
      4: "નીચેના વિકલ્પોમાંથી કોઈપણ એક પર ક્લિક કરો",
      12: "ନିମ୍ନଲିଖିତ ବିକଳ୍ପଗୁଡ଼ିକ ମଧ୍ୟରୁ ଯେକ one ଣସି ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ",
      6: "ಕೆಳಗಿನ ಯಾವುದೇ ಆಯ್ಕೆಗಳ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
      10: "তলৰ যিকোনো এটা বিকল্পত ক্লিক কৰক",
      8: "దిగువ ఎంపికలలో ఏదైనా ఒకదానిపై క్లిక్ చేయండి",
    },
    "Do not close this message, While your result is generating... <br>Your result will also be available in the profile section": {
      2: "इस संदेश को बंद न करें, जब तक आपका परिणाम उत्पन्न नहीं हो रहा है... <br>आपका परिणाम प्रोफ़ाइल अनुभाग में भी उपलब्ध होगा",
      3: "ਹੇਠਾਂ ਦਿੱਤੇ ਵਿਕਲਪਾਂ ਵਿੱਚੋਂ ਕਿਸੇ ਇੱਕ 'ਤੇ ਕਲਿੱਕ ਕਰੋ ਇਸ ਸੁਨੇਹੇ ਨੂੰ ਬੰਦ ਨਾ ਕਰੋ, ਜਦੋਂ ਤੁਹਾਡਾ ਨਤੀਜਾ ਤਿਆਰ ਹੋ ਰਿਹਾ ਹੈ... <br>ਤੁਹਾਡਾ ਨਤੀਜਾ ਪ੍ਰੋਫਾਈਲ ਸੈਕਸ਼ਨ ਵਿੱਚ ਵੀ ਉਪਲਬਧ ਹੋਵੇਗਾ।",
      4: "જ્યારે તમારું પરિણામ તૈયાર થાય ત્યારે આ સંદેશને બંધ કરશો નહીં... તમારું પરિણામ પ્રોફાઇલ વિભાગમાં અને નોંધાયેલ ઈમેલ પણ ઉપલબ્ધ રહેશે",
      12: "ଏହି ଫଳାଫଳ ବନ୍ଦ କରନ୍ତୁ ନାହିଁ, ଯେତେବେଳେ ଆପଣଙ୍କର ଫଳାଫଳ ସୃଷ୍ଟି କରୁଛି ... <br> ଆପଣଙ୍କର ଫଳାଫଳ ପ୍ରୋଫାଇଲ୍ ବିଭାଗରେ ମଧ୍ୟ ଉପଲବ୍ଧ ହେବ",
      6: "ಈ ಸಂದೇಶವನ್ನು ಮುಚ್ಚಬೇಡಿ, ನಿಮ್ಮ ಫಲಿತಾಂಶವು ರಚಿಸುತ್ತಿರುವಾಗ... <br>ನಿಮ್ಮ ಫಲಿತಾಂಶವು ಪ್ರೊಫೈಲ್ ವಿಭಾಗದಲ್ಲಿಯೂ ಸಹ ಲಭ್ಯವಿರುತ್ತದೆ",
      10: "এই বাৰ্তাটো বন্ধ নকৰিব, আপোনাৰ ফলাফল সৃষ্টি কৰাৰ সময়ত... <br>আপোনাৰ ফলাফল আলেখ্যন অংশতো উপলব্ধ হ'ব",
      8: "ఈ సందేశాన్ని మూసివేయవద్దు, మీ ఫలితం రూపొందుతున్నప్పుడు... <br>మీ ఫలితం ప్రొఫైల్ విభాగంలో కూడా అందుబాటులో ఉంటుంది",
    },
    "Logged Out": {
      2: "लॉग आउट सफल",
      3: "ਲੌਗ ਆਊਟ ਕੀਤਾ",
      4: "લૉગ આઉટ સફળ",
      12: "ଲଗ୍ ଆଉଟ୍",
      6: "ಲಾಗ್ ಔಟ್",
      10: "লগ আউট হৈছে", 8: "లాగ్ అవుట్ చేసారు",
    },
    "user name incorrect": {
      2: "उपयोगकर्ता नाम गलत है",
      3: "ਉਪਭੋਗਤਾ ਨਾਮ ਗਲਤ ਹੈ",
      4: "વપરાશકર્તા નામ ખોટું છે",
      12: "ବ୍ୟବହାରକାରୀ ନାମ ଭୁଲ",
      6: "ಬಳಕೆದಾರರ ಹೆಸರು ತಪ್ಪಾಗಿದೆ",
      10: "ব্যৱহাৰকাৰীৰ নাম ভুল", 8: "వినియోగదారు పేరు తప్పు",
    },
    "Account not registered!": {
      2: "खाता पंजीकृत नहीं है!",
      3: "ਖਾਤਾ ਰਜਿਸਟਰ ਨਹੀਂ ਹੋਇਆ!",
      4: "એકાઉન્ટ નોંધાયેલ નથી!",
      12: "ଖାତା ପଞ୍ଜିକୃତ ନୁହେଁ!",
      6: "ಖಾತೆ ನೋಂದಣಿಯಾಗಿಲ್ಲ!",
      10: "একাউণ্ট পঞ্জীয়ন কৰা হোৱা নাই!", 8: "ఖాతా నమోదు కాలేదు!",
    },
    "Internet Error! please check your internet": {
      2: "इंटरनेट की समस्या! कृपया अपना इंटरनेट जांचें",
      3: "ਇੰਟਰਨੈੱਟ ਗੜਬੜ! ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇੰਟਰਨੈਟ ਦੀ ਜਾਂਚ ਕਰੋ",
      4: "ઈન્ટરનેટ સમસ્યા! કૃપા કરીને તમારું ઇન્ટરનેટ તપાસો",
      12: "ଇଣ୍ଟରନେଟ୍ ତ୍ରୁଟି! ଦୟାକରି ଆପଣଙ୍କର ଇଣ୍ଟରନେଟ୍ ଯାଞ୍ଚ କରନ୍ତୁ",
      6: "ಇಂಟರ್ನೆಟ್ ದೋಷ! ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಂಟರ್ನೆಟ್ ಅನ್ನು ಪರಿಶೀಲಿಸಿ",
      10: "ইণ্টাৰনেট ত্ৰুটি! অনুগ্ৰহ কৰি আপোনাৰ ইণ্টাৰনেট পৰীক্ষা কৰক",
      8: "ఇంటర్నెట్ లోపం! దయచేసి మీ ఇంటర్నెట్‌ని తనిఖీ చేయండి",
    },
    "OTP should be 6 digit": {
      2: "ओ. टी. पी. 6 अंकों का होना चाहिए",
      3: "OTP 6 ਅੰਕਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
      4: "ઓ.ટી.પી.6 અંકનો હોવો જોઈએ",
      12: "OTP 6 ଅଙ୍କ ହେବା ଉଚିତ୍",
      6: "OTP 6 ಅಂಕಿಗಳಾಗಿರಬೇಕು",
      10: "Otp ৬ অংক হ’ব লাগে", 8: "Otp 6 అంకెలు ఉండాలి",
    },
    "Invalid Otp": {
      2: "अमान्य ओ. टी. पी.",
      3: "ਅਵੈਧ Otp",
      4: "અમાન્ય ઓ.ટી.પી.",
      12: "ଅବ val ଧ Otp",
      6: "ಅಮಾನ್ಯ Otp",
      10: "অবৈধ Otp",
      8: "చెల్లని Otp",
    },
    "password & confirm password mismatch": {
      2: "पासवर्ड और पुष्टि पासवर्ड मेल नहीं खाते",
      3: "ਪਾਸਵਰਡ ਅਤੇ ਪਾਸਵਰਡ ਬੇਮੇਲ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
      4: "પાસવર્ડ અને કન્ફર્મ પાસવર્ડ મેળ ખાતા નથી",
      12: "ପାସୱାର୍ଡ ଏବଂ ପାସୱାର୍ଡ ମେଳ ଖାଇବାକୁ ନିଶ୍ଚିତ କରନ୍ତୁ",
      6: "ಪಾಸ್ವರ್ಡ್ ಮತ್ತು ಪಾಸ್ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗದಂತೆ ದೃಢೀಕರಿಸಿ",
      10: "পাছৱৰ্ড আৰু পাছৱৰ্ডৰ মিল নথকা নিশ্চিত কৰক",
      8: "పాస్వర్డ్ & పాస్వర్డ్ సరిపోలని నిర్ధారించండి",
    },
    "Error while reseting the password": {
      2: "पासवर्ड रीसेट करते समय त्रुटि",
      3: "ਪਾਸਵਰਡ ਰੀਸੈੱਟ ਕਰਨ ਦੌਰਾਨ ਗਲਤੀ",
      4: "પાસવર્ડ રીસેટ કરતી વખતે ભૂલ",
      12: "ପାସୱାର୍ଡ ପୁନ res ସେଟ୍ କରିବା ସମୟରେ ତ୍ରୁଟି",
      6: "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸುವಾಗ ದೋಷ",
      10: "পাছৱৰ্ড পুনৰায় সেট কৰাৰ সময়ত ত্ৰুটি",
      8: "పాస్‌వర్డ్‌ని రీసెట్ చేస్తున్నప్పుడు ఎర్రర్ ఏర్పడింది",
    },
    "Language api fail": {
      2: "भाषा एपीआई विफल",
      3: "ਭਾਸ਼ਾ ਏਪੀਆਈ ਅਸਫਲ",
      4: "ભાષા એ.પી.આઈ નિષ્ફળ",
      12: "ଭାଷା api ବିଫଳ",
      6: "ಭಾಷಾ ಅಪಿ ವಿಫಲವಾಗಿದೆ",
      10: "ভাষা api বিফল",
      8: "భాషా api విఫలమైంది",
    },
    "No data to send to quiz": {
      2: "प्रश्नोत्तरी में भेजने के लिए कोई डेटा नहीं",
      3: "ਕਵਿਜ਼ ਵਿੱਚ ਭੇਜਣ ਲਈ ਕੋਈ ਡਾਟਾ ਨਹੀਂ ਹੈ",
      4: "ક્વિઝમાં મોકલવા માટે કોઈ ડેટા નથી",
      12: "କୁଇଜ୍ ପଠାଇବାକୁ କ data ଣସି ତଥ୍ୟ ନାହିଁ",
      7: "কুইজলৈ পঠিয়াবলৈ কোনো তথ্য নাই",
      10: "క్విజ్‌కి పంపడానికి డేటా లేదు",
      6: "ರಸಪ್ರಶ್ನೆಗೆ ಕಳುಹಿಸಲು ಯಾವುದೇ ಡೇಟಾ ಇಲ್ಲ"
    },
    "Error in guest login": {
      2: "अतिथि लॉगिन में त्रुटि",
      3: "ਮਹਿਮਾਨ ਲੌਗਇਨ ਵਿੱਚ ਤਰੁੱਟੀ",
      4: "મહેમાન લૉગિન ભૂલ",
      12: "ଅତିଥି ଲଗଇନରେ ତ୍ରୁଟି",
      10: "অতিথি প্ৰৱেশত ত্ৰুটি", 8: "అతిథి లాగిన్‌లో లోపం",
      6: "ಅತಿಥಿ ಲಾಗಿನ್‌ನಲ್ಲಿ ದೋಷ"
    },
    "empty data": {
      2: "खाली डेटा",
      3: "ਖਾਲੀ ਡਾਟਾ",
      4: "ખાલી ડેટા",
      12: "ଖାଲି ତଥ୍ୟ",
      10: "খালী তথ্য", 8: "ఖాళీ డేటా",
      6: "ಖಾಲಿ ಡೇಟಾ"
    },
    "Fatal Error: Login verification failed, Please try again": {
      2: "गंभीर त्रुटि: लॉगिन सत्यापन विफल, कृपया पुनः प्रयास करें",
      3: "ਖਾਲੀ ਡਾਟਾ ਘਾਤਕ ਗਲਤੀ: ਲੌਗਇਨ ਪੁਸ਼ਟੀਕਰਨ ਅਸਫਲ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "ગંભીર ભૂલ: લૉગિન ચકાસણી નિષ્ફળ, કૃપા કરીને ફરી પ્રયાસ કરો",
      12: "ସାଂଘାତିକ ତ୍ରୁଟି: ଲଗଇନ୍ ଯାଞ୍ଚ ବିଫଳ ହେଲା, ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
      10: "মাৰাত্মক ত্ৰুটি: প্ৰৱেশ সত্যাপন ব্যৰ্থ, অনুগ্ৰহ কৰি পুনৰ চেষ্টা কৰক",
      8: "ఘోరమైన లోపం: లాగిన్ ధృవీకరణ విఫలమైంది, దయచేసి మళ్లీ ప్రయత్నించండి",
      6: "ಮಾರಣಾಂತಿಕ ದೋಷ: ಲಾಗಿನ್ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
    },
    "Some Error Occured": {
      2: "कुछ त्रुटि हुई है",
      3: "ਕੁਝ ਗਲਤੀ ਆਈ ਹੈ",
      4: "કેટલીક ભૂલ આવી છે",
      12: "କିଛି ତ୍ରୁଟି ଘଟିଲା",
      10: "কিছু ভুল সংঘটিত হৈছে", 8: "కొంత లోపం సంభవించింది",
      6: "ಕೆಲವು ದೋಷ ಸಂಭವಿಸಿದೆ"
    },
    "Username or password incorrect": {
      2: "उपयोगकर्ता नाम या पासवर्ड गलत है",
      3: "ਉਪਭੋਗਤਾ ਨਾਮ ਜਾਂ ਪਾਸਵਰਡ ਗਲਤ ਹੈ",
      4: "વપરાશકર્તા નામ અથવા પાસવર્ડ ખોટો છે",
      12: "ଉପଯୋଗକର୍ତ୍ତା ନାମ କିମ୍ବା ପାସୱାର୍ଡ ଭୁଲ୍",
      10: "ব্যৱহাৰকাৰীৰ নাম বা পাছৱৰ্ড ভুল",
      8: "యూజర్ పేరు లేదా పాస్వర్డ్ చెల్లదు",
      6: "ಬಳಕೆದಾರಹೆಸರು ಅಥವಾ ಪಾಸ್‌ವರ್ಡ್ ತಪ್ಪಾಗಿದೆ"
    },
    "Logged in successfully": {
      2: "सफलतापूर्वक लॉग इन हो चुका है",
      3: "ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕੀਤਾ ਗਿਆ",
      4: "સફળતાપૂર્વક લૉગ ઇન થયું",
      12: "ସଫଳତାର ସହିତ ଲଗ୍ ଇନ୍ କରନ୍ତୁ",
      10: "সফলতাৰে লগ ইন কৰা হৈছে",
      8: "విజయవంతంగా లాగిన్ చేసారు",
      6: "ಯಶಸ್ವಿಯಾಗಿ ಲಾಗ್ ಇನ್ ಮಾಡಲಾಗಿದೆ"
    },
    "Login successfully, Please wait Test is submitting": {
      2: "सफलतापूर्वक लॉग इन हो चुका है",
      3: "ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕਰੋ, ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ ਟੈਸਟ ਜਮ੍ਹਾਂ ਹੋ ਰਿਹਾ ਹੈ",
      4: "સફળતાપૂર્વક લૉગ ઇન થયું",
      12: "ସଫଳତାର ସହିତ ଲଗଇନ୍ କରନ୍ତୁ, ଦୟାକରି ପରୀକ୍ଷା ଦାଖଲ କରିବାକୁ ଅପେକ୍ଷା କରନ୍ତୁ",
      10: "সফলতাৰে লগইন কৰক, অনুগ্ৰহ কৰি অপেক্ষা কৰক পৰীক্ষা জমা দিয়া হৈছে",
      8: "విజయవంతంగా లాగిన్ చేయండి, పరీక్ష సమర్పించబడుతోంది",
      6: "ಯಶಸ್ವಿಯಾಗಿ ಲಾಗಿನ್ ಮಾಡಿ, ಪರೀಕ್ಷೆಯನ್ನು ಸಲ್ಲಿಸುತ್ತಿದೆ ಎಂದು ನಿರೀಕ್ಷಿಸಿ"
    },
    "Test successfully Submitted": {
      2: "परीक्षण सफलतापूर्वक सबमिट कर दिया गया है",
      3: "ਟੈਸਟ ਸਫਲਤਾਪੂਰਵਕ ਸਪੁਰਦ ਕੀਤਾ ਗਿਆ",
      4: "પરીક્ષણ સફળતાપૂર્વક સબમિટ કરવામાં આવ્યું છે",
      12: "ପରୀକ୍ଷା ସଫଳତାର ସହିତ ଦାଖଲ",
      10: "পৰীক্ষা সফলতাৰে জমা দিয়া হৈছে",
      8: "పరీక్ష విజయవంతంగా సమర్పించబడింది",
      6: "ಪರೀಕ್ಷೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ"
    },
    "Test Submission failed, try again": {
      2: "परीक्षण सबमिशन विफल, पुनः प्रयास करें",
      3: "ਟੈਸਟ ਸਪੁਰਦਗੀ ਅਸਫਲ, ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "પરીક્ષણ સબમિશન નિષ્ફળ થયું, ફરી પ્રયાસ કરો",
      12: "ପରୀକ୍ଷା ଦାଖଲ ବିଫଳ ହେଲା, ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
      10: "পৰীক্ষা জমা দিয়া বিফল, পুনৰ চেষ্টা কৰক",
      8: "పరీక్ష సమర్పణ విఫలమైంది, మళ్లీ ప్రయత్నించండి",
      6: "ಪರೀಕ್ಷಾ ಸಲ್ಲಿಕೆ ವಿಫಲವಾಗಿದೆ, ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
    },
    "Transaction cancelled.": {
      2: "ट्रांजेक्शन रद्द",
      3: "ਲੈਣ-ਦੇਣ ਰੱਦ ਕੀਤਾ ਗਿਆ",
      4: "ટ્રાનસાકશન રદ કર્યો ",
      12: "କାରବାର ବାତିଲ ହୋଇଛି",
      10: "লেনদেন বাতিল",
      8: "లావాదేవీ రద్దు చేయబడింది",
      6: "ವಹಿವಾಟು ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ."
    },
    "If your balance is debited from your account, then contact our support center": {
      2: "यदि आपके खाते से आपकी शेष राशि डेबिट हो जाती है, तो हमारे सहायता केंद्र से संपर्क करें",
      3: "ਜੇਕਰ ਤੁਹਾਡਾ ਬਕਾਇਆ ਤੁਹਾਡੇ ਖਾਤੇ ਤੋਂ ਡੈਬਿਟ ਹੋ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਸਾਡੇ ਸਹਾਇਤਾ ਕੇਂਦਰ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
      4: "જો તમારું બેલેન્સ તમારા ખાતામાંથી ડેબિટ થયું હોય, તો અમારા સહાય કેન્દ્રનો સંપર્ક કરો",
      12: "ଯଦି ଆପଣଙ୍କର ବାଲାନ୍ସ ଆପଣଙ୍କ ଆକାଉଣ୍ଟରୁ ଡେବିଟ୍ ହୋଇଛି, ତେବେ ଆମର ସମର୍ଥନ କେନ୍ଦ୍ର ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ",
      10: "যদি আপোনাৰ বেলেঞ্চ আপোনাৰ একাউণ্টৰ পৰা ডেবিট কৰা হয়, তেন্তে আমাৰ সমৰ্থন কেন্দ্ৰৰ সৈতে যোগাযোগ কৰক",
      8: "మీ ఖాతా నుండి మీ బ్యాలెన్స్ డెబిట్ అయినట్లయితే, మా మద్దతు కేంద్రాన్ని సంప్రదించండి",
      6: "ನಿಮ್ಮ ಖಾತೆಯಿಂದ ನಿಮ್ಮ ಬ್ಯಾಲೆನ್ಸ್ ಡೆಬಿಟ್ ಆಗಿದ್ದರೆ, ನಂತರ ನಮ್ಮ ಬೆಂಬಲ ಕೇಂದ್ರವನ್ನು ಸಂಪರ್ಕಿಸಿ"
    },
    "Please enter coupon first then apply": {
      2: "कृपया पहले कूपन दर्ज करें फिर आवेदन करें",
      3: "ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਕੂਪਨ ਦਰਜ ਕਰੋ ਫਿਰ ਅਪਲਾਈ ਕਰੋ",
      4: "કૃપા કરીને પહેલા કૂપન દાખલ કરો અને પછી અરજી કરો",
      12: "ଦୟାକରି ପ୍ରଥମେ କୁପନ ପ୍ରବେଶ କରନ୍ତୁ ତାପରେ ଆବେଦନ କରନ୍ତୁ",
      6: "ದಯವಿಟ್ಟು ಮೊದಲು ಕೂಪನ್ ನಮೂದಿಸಿ ನಂತರ ಅನ್ವಯಿಸಿ",
      10: "অনুগ্ৰহ কৰি প্ৰথমে কুপন দিয়ক তাৰ পিছত আবেদন কৰক",
      8: "దయచేసి ముందుగా కూపన్‌ని నమోదు చేసి, ఆపై దరఖాస్తు చేసుకోండి",
    },
    "Something went wrong please try again": {
      2: "कुछ गलत हो गया। कृपया पुन: प्रयास करें",
      3: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "કંઈક ખોટું થયું. મહેરબાની કરીને ફરીથી પ્રયતન કરો",
      12: "କିଛି ଭୁଲ ହୋଇଗଲା ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
      6: "ಏನೋ ತಪ್ಪಾಗಿದೆ ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
      10: "কিবা ভুল হ'ল অনুগ্ৰহ কৰি আকৌ এবাৰ চেষ্টা কৰক",
      8: "ఏదో తప్పు జరిగింది, దయచేసి మళ్లీ ప్రయత్నించండి",
    },
    "No Coupon found": {
      2: "कोई कूपन नहीं मिला",
      3: "ਕੋਈ ਕੂਪਨ ਨਹੀਂ ਮਿਲਿਆ",
      4: "કોઈ કૂપન મળ્યો નથી",
      12: "କ C ଣସି କୁପନ୍ ମିଳିଲା ନାହିଁ",
      10: "কোনো কুপন পোৱা নগ'ল",
      8: "కూపన్ కనుగొనబడలేదు",
      6: "ಯಾವುದೇ ಕೂಪನ್ ಕಂಡುಬಂದಿಲ್ಲ"
    },
    "No amount found to pay, Please try to submit test again": {
      2: "भुगतान के लिए कोई राशि नहीं मिली, कृपया फिर से परीक्षा सबमिट करने का प्रयास करें",
      3: "ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਕੋਈ ਰਕਮ ਨਹੀਂ ਮਿਲੀ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਟੈਸਟ ਜਮ੍ਹਾ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "ચુકવણી માટે કોઈ રકમ પ્રાપ્ત થઈ નથી, કૃપા કરીને ફરીથી પરીક્ષા સબમિટ કરવાનો પ્રયાસ કરો",
      12: "ଦେୟ ଦେବାକୁ କ found ଣସି ରାଶି ମିଳିଲା ନାହିଁ, ଦୟାକରି ପୁନର୍ବାର ପରୀକ୍ଷା ଦାଖଲ କରିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ",
      10: "কোনো ধৰণৰ ধন দিবলৈ পোৱা নগ'ল, অনুগ্ৰহ কৰি পুনৰ পৰীক্ষা জমা দিবলৈ চেষ্টা কৰক",
      8: "చెల్లించడానికి మొత్తం కనుగొనబడలేదు, దయచేసి పరీక్షను మళ్లీ సమర్పించడానికి ప్రయత్నించండి",
      6: "ಪಾವತಿಸಲು ಯಾವುದೇ ಮೊತ್ತ ಕಂಡುಬಂದಿಲ್ಲ, ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪರೀಕ್ಷೆಯನ್ನು ಸಲ್ಲಿಸಲು ಪ್ರಯತ್ನಿಸಿ"
    },
    "  No data found in our directory": {
      2: "हमारी डायरेक्टरी में कोई डेटा नहीं मिला",
      3: "ਸਾਡੀ ਡਾਇਰੈਕਟਰੀ ਵਿੱਚ ਕੋਈ ਡਾਟਾ ਨਹੀਂ ਮਿਲਿਆ",
      4: "અમારી ડિરેક્ટરીમાં કોઈ ડેટા મળ્યો નથી",
      12: "ଆମ ଡିରେକ୍ଟୋରୀରେ କ data ଣସି ତଥ୍ୟ ମିଳିଲା ନାହିଁ",
      10: "আমাৰ ডাইৰেক্টৰীত কোনো তথ্য পোৱা নগ'ল",
      8: "మా డైరెక్టరీలో డేటా ఏదీ కనుగొనబడలేదు",
      6: "ನಮ್ಮ ಡೈರೆಕ್ಟರಿಯಲ್ಲಿ ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ"
    },
    "100% Discount applied, proceeding to the test page": {
      2: "100% छूट लागू की गई, अब परीक्षण पृष्ठ पर जा रहे हैं",
      3: "100% ਛੂਟ ਲਾਗੂ ਕੀਤੀ ਗਈ, ਟੈਸਟ ਪੰਨੇ 'ਤੇ ਜਾ ਕੇ",
      4: "100% ડિસ્કાઉન્ટ લાગુ, હવે ટેસ્ટ પેજ પર જઈએ છીએ",
      12: "100% ରିହାତି ପ୍ରୟୋଗ, ପରୀକ୍ଷା ପୃଷ୍ଠାକୁ ଅଗ୍ରସର",
      10: "100% ৰেহাই প্ৰয়োগ কৰা হৈছে, পৰীক্ষা পৃষ্ঠালৈ আগবাঢ়িব",
      8: "100% తగ్గింపు వర్తించబడుతుంది, పరీక్ష పేజీకి కొనసాగుతోంది",
      6: "100% ರಿಯಾಯಿತಿ ಅನ್ವಯಿಸಲಾಗಿದೆ, ಪರೀಕ್ಷಾ ಪುಟಕ್ಕೆ ಮುಂದುವರಿಯುತ್ತಿದೆ"
    },
    "Please wait your result PDF is downloading": {
      2: "कृपया प्रतीक्षा करें आपका परिणाम पीडीएफ डाउनलोड हो रहा है",
      3: "ਕਿਰਪਾ ਕਰਕੇ ਤੁਹਾਡਾ ਨਤੀਜਾ PDF ਡਾਊਨਲੋਡ ਹੋਣ ਦੀ ਉਡੀਕ ਕਰੋ",
      4: "કૃપા કરીને તમારા પરિણામ પીડીએફ ડાઉનલોડિંગની રાહ જુઓ",
      12: "ଦୟାକରି ଆପଣଙ୍କର ଫଳାଫଳ PDF ଡାଉନଲୋଡ୍ କରିବାକୁ ଅପେକ୍ଷା କରନ୍ତୁ",
      10: "অনুগ্ৰহ কৰি অপেক্ষা কৰক আপোনাৰ ফলাফল PDF ডাউনলোড হৈ আছে",
      8: "దయచేసి మీ ఫలితం PDF డౌన్‌లోడ్ అవుతోంది",
      6: "ನಿಮ್ಮ ಫಲಿತಾಂಶ PDF ಡೌನ್‌ಲೋಡ್ ಆಗುತ್ತಿದೆ ಎಂದು ನಿರೀಕ್ಷಿಸಿ"
    },
    "Enter Valid Email Number": {
      2: "मान्य ईमेल नंबर दर्ज करें",
      3: "ਵੈਧ ਈਮੇਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
      4: "માન્ય ઈમેલ નંબર દાખલ કરો",
      12: "ବ id ଧ ଇମେଲ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
      10: "বৈধ ইমেইল নম্বৰ দিয়ক",
      8: "చెల్లుబాటు అయ్యే ఇమెయిల్/ నంబర్‌ను నమోదు చేయండి",
      6: "ಮಾನ್ಯ ಇಮೇಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
    },
    "Registered successfully": {
      2: "सफलतापूर्वक पंजीकृत",
      3: "ਸਫਲਤਾਪੂਰਵਕ ਰਜਿਸਟਰ ਕੀਤਾ ਗਿਆ",
      4: "સફળતાપૂર્વક નોંધણી થઈ",
      12: "ସଫଳତାର ସହିତ ପଞ୍ଜିକୃତ",
      10: "সফলতাৰে পঞ্জীয়ন কৰা হৈছে",
      8: "విజయవంతంగా నమోదు చేయబడింది",
      6: "ಯಶಸ್ವಿಯಾಗಿ ನೋಂದಾಯಿಸಲಾಗಿದೆ"
    },
    "Registeration failed, please try again": {
      2: "पंजीकरण विफल, कृपया पुन: प्रयास करें",
      3: "ਰਜਿਸਟਰੇਸ਼ਨ ਅਸਫਲ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "નોંધણી નિષ્ફળ, કૃપા કરીને ફરી પ્રયાસ કરો",
      12: "ପଞ୍ଜୀକରଣ ବିଫଳ ହେଲା, ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
      10: "পঞ্জীয়ন বিফল, অনুগ্ৰহ কৰি পুনৰ চেষ্টা কৰক",
      8: "నమోదు విఫలమైంది, దయచేసి మళ్లీ ప్రయత్నించండి",
      6: "ನೋಂದಣಿ ವಿಫಲವಾಗಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
    },
    "TIME is up!! you have 60 minutes to submit": {
      2: "समय पूरा हो गया!! सबमिट करने के लिए आपके पास 60 मिनट हैं",
      3: "ਸਮਾਂ ਖਤਮ!! ਤੁਹਾਡੇ ਕੋਲ ਜਮ੍ਹਾਂ ਕਰਨ ਲਈ 60 ਮਿੰਟ ਹਨ",
      4: "સમય પૂરો થયો!! તમારી પાસે સબમિટ કરવા માટે 60 મિનિટ છે",
      12: "ସମୟ ସମାପ୍ତ !! ଆପଣଙ୍କର ଦାଖଲ କରିବାକୁ 60 ମିନିଟ୍ ଅଛି",
      10: "TIME শেষ হ'ল!! জমা দিবলৈ আপোনাৰ হাতত ৬০ মিনিট সময় আছে",
      8: "సమయం ముగిసింది!! మీరు సమర్పించడానికి 60 నిమిషాల సమయం ఉంది",
      6: "ಸಮಯ ಮುಗಿತು!! ನೀವು ಸಲ್ಲಿಸಲು 60 ನಿಮಿಷಗಳ ಕಾಲಾವಕಾಶವಿದೆ"
    },
    "No questions for this test found": {
      2: "इस परीक्षण के लिए कोई प्रश्न नहीं मिला",
      3: "ਇਸ ਟੈਸਟ ਲਈ ਕੋਈ ਸਵਾਲ ਨਹੀਂ ਮਿਲੇ",
      4: "આ કસોટી માટે કોઈ પ્રશ્નો મળ્યા નથી",
      12: "ଏହି ପରୀକ୍ଷା ପାଇଁ କ questions ଣସି ପ୍ରଶ୍ନ ମିଳିଲା ନାହିଁ",
      10: "এই পৰীক্ষাৰ বাবে কোনো প্ৰশ্ন পোৱা নগ'ল",
      8: "ఈ పరీక్ష కోసం ప్రశ్నలు ఏవీ కనుగొనబడలేదు",
      6: "ಈ ಪರೀಕ್ಷೆಗೆ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳು ಕಂಡುಬಂದಿಲ್ಲ"
    },
    "Test submission failed, try again": {
      2: "परीक्षण सबमिशन विफल, पुनः प्रयास करें",
      3: "ਟੈਸਟ ਸਪੁਰਦਗੀ ਅਸਫਲ ਰਹੀ, ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
      4: "પરીક્ષણ સબમિશન નિષ્ફળ થયું, ફરી પ્રયાસ કરો",
      12: "ପରୀକ୍ଷା ଦାଖଲ ବିଫଳ ହେଲା, ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ",
      10: "পৰীক্ষা জমা দিয়া বিফল, পুনৰ চেষ্টা কৰক",
      8: "పరీక్ష సమర్పణ విఫలమైంది, మళ్లీ ప్రయత్నించండి",
      6: "ಪರೀಕ್ಷೆಯ ಸಲ್ಲಿಕೆ ವಿಫಲವಾಗಿದೆ, ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ"
    },
    "Below 18 Years": {
      2: "18 वर्ष से कम",
      3: "18 ਸਾਲ ਤੋਂ ਘੱਟ",
      4: "18 વર્ષથી નીચે",
      12: "18 ବର୍ଷ ତଳେ",
      10: "18 বছৰৰ তলৰ",
      8: "18 సంవత్సరాల లోపు",
      6: "18 ವರ್ಷಗಳ ಕೆಳಗೆ"
    },
    "18 Years or Above": {
      2: "18 वर्ष या उससे अधिक",
      3: "18 ਸਾਲ ਜਾਂ ਇਸ ਤੋਂ ਵੱਧ",
      4: "18 વર્ષ અને તેથી વધુ",
      12: "18 ବର୍ଷ କିମ୍ବା ତଦୁର୍ଦ୍ଧ",
      10: "18 বছৰ বা তাৰ ওপৰৰ",
      8: "18 సంవత్సరాలు లేదా అంతకంటే ఎక్కువ",
      6: "18 ವರ್ಷಗಳು ಅಥವಾ ಮೇಲ್ಪಟ್ಟವರು"
    },
    "Get started": {
      2: "शुरू हो जाओ"
    },
    "Choose your path to begin your tests": {
      2: "अपने परीक्षण शुरू करने के लिए अपना रास्ता चुनें"
    },
    "For Users": {
      2: "उपयोगकर्ताओं के लिए"
    },
    "For Psychologists": {
      2: "मनोवैज्ञानिकों के लिए"
    },
    "Explore the assessments and understand your mental health and personal strengths": {
      2: "मूल्यांकनों का अन्वेषण करें और अपने मानसिक स्वास्थ्य और व्यक्तिगत शक्तियों को समझें"
    },
    "Access Professional Assessment Tools and provide clinical assessments": {
      2: "व्यावसायिक मूल्यांकन उपकरण तक पहुँचें और नैदानिक ​​मूल्यांकन प्रदान करें"
    },
    "Discover your true self": {
      2: "अपने सच्चे स्व की खोज करें"
    },
    "Comprehensive Psychometric Tests for every aspect of your Well-being": {
      2: "आपकी भलाई के हर पहलू के लिए व्यापक साइकोमेट्रिक परीक्षण"
    },
    "Choose your own Future": {
      2: "अपना भविष्य स्वयं चुनें"
    },
    "Unlock your Potential with our Psychometric Tests": {
      2: "हमारे साइकोमेट्रिक टेस्ट के साथ अपनी क्षमता को अनलॉक करें"
    },
    "Choose from a variety of tests to gain deeper insights into your abilities": {
      2: "अपनी क्षमताओं के बारे में गहन जानकारी प्राप्त करने के लिए विभिन्न प्रकार के परीक्षणों में से चुनें"
    },
    "No Test Found. Please Choose Different Language": {
      2: "कोई मूल्यांकन नहीं मिला. कृपया भिन्न भाषा चुनें"
    },
    "Psychologist Assessment Tools": {
      2: "मनोवैज्ञानिक मूल्यांकन उपकरण"
    },
    "Evaluate professional skills and mental well-being as a psychologist": {
      2: "एक मनोवैज्ञानिक के रूप में पेशेवर कौशल और मानसिक कल्याण का मूल्यांकन करें"
    },
    "Start": {
      2: "शुरू करे"
    },
    "My Tests": {
      2: "मेरे परीक्षण"
    },
    "Test name": {
      2: "परीक्षण का नाम"
    },
    "Quantity": {
      2: "मात्रा"
    },
    " tests": {
      2: "परीक्षण"
    },
    "Payment": {
      2: "भुगतान"
    },
    "Tests will be available soon in this language. Stay tuned!": {
      2: "इस भाषा में जल्द ही टेस्ट उपलब्ध होंगे। बने रहें!"
    },
    "No worries, we’ll send you reset intructions":{
      2: "कोई चिंता नहीं, हम आपको रीसेट निर्देश भेज देंगे।"
    },
    "Password reset":{
      2:"पासवर्ड रीसेट"
    },
    "We sent a code to ":{
      2: "हमने आपको इसपर कोड भेजा है "
    },
    "Didn’t receive the email?":{
      2: "क्या आपको ईमेल प्राप्त नहीं हुआ?"
    },
    "Click here":{
      2: "यहाँ क्लिक करें"
    },
    "Set new password":{
      2:"नया पासवर्ड सेट करें"
    },
    "Good to be at least 8 characters":{
      2: "कम से कम 8 अक्षर होना अच्छा है"
    },
    "Profile overview":{
      2: "प्रोफ़ाइल अवलोकन"
    },
    "Personal Information":{
      2: "व्यक्तिगत जानकारी"
    },
    "Account settings":{
      2: "अकाउंट सेटिंग्स"
    },
    "Edit":{
      2:"बदलें"
    },
    "Date":{
      2: "तारीख"
    },
    "Tests":{
      2: "परीक्षण"
    },
    "View Report":{
      2: "रिपोर्ट देखें"
    },
    "Download":{
      2: "डाउनलोड करें"
    },
    "Already have an account?":{
      2: "क्या आपका पहले से एक अकाउंट है?"
    },
    "Login Here":{
      2: "यहाँ लॉगिन करें"
    },
    "Register as Psychologist":{
      2: "मनोवैज्ञानिक के रूप में पंजीकरण करें"
    },
    "Phone Number":{
      2: "फ़ोन नंबर"
    },
    "Qualification":{
      2: "योग्यता"
    },
    "A confirmation will be sent to your registered email address. Please check your inbox for further instructions":{
      2: "आपके पंजीकृत ईमेल पते पर एक पुष्टिकरण भेजा जाएगा। कृपया आगे के निर्देशों के लिए अपना इनबॉक्स जांचें"
    },
    "Account Creation Request Submitted": {
      2: "खाता निर्माण अनुरोध सबमिट किया गया"
    },
    "Thank you for submitting your account creation request. Our team will review your details and confirm your account shortly.":{
      2: "अपना खाता निर्माण अनुरोध सबमिट करने के लिए धन्यवाद। हमारी टीम आपके विवरण की समीक्षा करेगी और शीघ्र ही आपके खाते की पुष्टि करेगी।"
    }


  }

  constructor(private AS: AuthService) { }

  transalateText(text: string) {
    let userLanguage: any = this.AS.commandinDB('get', 'selectedLanguage');
    if (this.TEXTS[text]) {
      text = this.TEXTS[text][userLanguage] || text
    }
    return text
  }
}
