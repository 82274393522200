import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { authGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgetpasswordComponent } from './components/forgetpassword/forgetpassword.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ViewResultComponent } from './components/view-result/view-result.component';

import path from 'path';
import { PsycoHomeComponent } from './components/psyco-home/psyco-home.component';
import { UserHomeComponent } from './components/user-home/user-home.component';
import { PsycoRegisterComponent } from './components/psyco-register/psyco-register.component';


export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
        // redirectTo: 'home',
        // pathMatch: 'full'
    },
   
  
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [authGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login/:quizId',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'register/:quizId',
        component: RegisterComponent
    },
    {
        path: 'psycologistRegister',
        component: PsycoRegisterComponent
    },
    {
        path: 'forgetpassword',
        component: ForgetpasswordComponent
    },
    {
        path: 'quiz/:testType',
        component: QuizComponent,
        canActivate: [authGuard]
    },
    {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [authGuard]
    },
    {
        path: 'payment/:routeData',
        component: PaymentComponent,
        canActivate: [authGuard]
    },
    {
        path: 'view-result',
        component: ViewResultComponent,
        canActivate: [authGuard]
    },
    {
        path: 'psycologist-home',
        component: PsycoHomeComponent,
        canActivate: [authGuard]
    },
    {
        path: 'user-home',
        component: UserHomeComponent
    }
];
